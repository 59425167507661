

























































































































import Vue from 'vue'
import Layout from "@/router/layouts/main.vue"
import PageHeader from "@/components/page-header.vue"

export default Vue.extend({
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "User settings",
      items: [
        {
          text: "User settings",
          href: "/",
          active: true
        }
      ],
    };
  },
});
